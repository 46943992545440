export const skills = [
  { skill_id: 1, skill: "Javascript", badge_url: "" },
  { skill_id: 3, skill: "React", badge_url: "" },
  { skill_id: 13, skill: "React Native", badge_url: "" },
  { skill_id: 6, skill: "Express", badge_url: "" },
  { skill_id: 7, skill: "SQL", badge_url: "" },
  { skill_id: 8, skill: "PostgreSQL", badge_url: "" },
  { skill_id: 2, skill: "Node.js", badge_url: "" },
  { skill_id: 4, skill: "HTML5", badge_url: "" },
  { skill_id: 14, skill: "jQuery", badge_url: "" },
  { skill_id: 5, skill: "CSS", badge_url: "" },
  { skill_id: 9, skill: "Jest", badge_url: "" },
  { skill_id: 10, skill: "TDD", badge_url: "" },
  { skill_id: 11, skill: "Version control with Git", badge_url: "" },
  { skill_id: 12, skill: "Pair Programming", badge_url: "" },
];
